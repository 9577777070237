export const shapeIcon = `<svg width="97" height="45" viewBox="0 0 97 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M54.6068 0.665039H2.1359C1.76059 0.665772 1.39207 0.764056 1.06732 0.950029C0.742566 1.136 0.472999 1.40312 0.285664 1.72459C0.0983294 2.04607 -0.000185771 2.41059 2.62987e-07 2.78158C0.000186297 3.15257 0.0990677 3.51699 0.286725 3.83828L16.536 31.6689C16.8593 32.2226 17.3244 32.6823 17.8845 33.0019C18.4446 33.3215 19.08 33.4896 19.7268 33.4893H72.146C72.5213 33.4894 72.8901 33.3918 73.2153 33.2065C73.5405 33.0211 73.8107 32.7545 73.9987 32.4333C74.1867 32.1121 74.2859 31.7477 74.2865 31.3766C74.287 31.0056 74.1887 30.6409 74.0016 30.3192L57.8008 2.4908C57.4778 1.93578 57.0124 1.47476 56.4517 1.15422C55.8909 0.833683 55.2546 0.664952 54.6068 0.665039Z" fill="#FB3283"/>
<path d="M75.5735 9.35178L23.1346 11.1833C22.7595 11.1971 22.3947 11.3082 22.0766 11.5054C21.7586 11.7026 21.4985 11.9789 21.3225 12.3067C21.1465 12.6346 21.0607 13.0023 21.0739 13.3731C21.087 13.7438 21.1986 14.1046 21.3973 14.4191L38.6081 41.6656C38.9505 42.2076 39.4314 42.6509 40.0023 42.9507C40.5733 43.2505 41.2141 43.3964 41.8605 43.3735L94.2477 41.5438C94.6229 41.5308 94.988 41.4204 95.3066 41.2239C95.6251 41.0273 95.8858 40.7514 96.0625 40.4238C96.2392 40.0963 96.3256 39.7286 96.3132 39.3578C96.3007 38.9869 96.1898 38.6259 95.9916 38.3109L78.8293 11.0649C78.4871 10.5215 78.006 10.077 77.4344 9.77627C76.8627 9.4755 76.2209 9.32908 75.5735 9.35178Z" fill="#FFBE84"/>
</svg>`;

export const footerLogo = `<svg width="91" height="29" viewBox="0 0 91 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M29.9437 15.4242H8.9606C8.06096 15.4242 7.22467 14.9409 6.78119 14.165L0.268306 2.85707C-0.0738103 2.25924 -0.0738103 1.54693 0.268306 0.949101C0.610422 0.351271 1.2313 -0.00488281 1.91553 -0.00488281H22.924C23.8237 -0.00488281 24.6599 0.478469 25.1034 1.2671L31.591 12.575C31.9331 13.1728 31.9331 13.8851 31.591 14.483C31.2488 15.0681 30.6406 15.4242 29.9437 15.4242ZM2.24498 2.06844L8.59314 13.1092C8.66917 13.2491 8.80855 13.3254 8.97327 13.3254H29.6396L23.3168 2.28468C23.2408 2.14476 23.1014 2.06844 22.9367 2.06844H2.24498Z" fill="#035537"/>
<path d="M29.9594 22.0507H8.97622C8.07658 22.0507 7.2403 21.5673 6.79681 20.7914L0.283931 9.48353C-0.0581853 8.8857 -0.0581853 8.1734 0.283931 7.57557C0.626047 6.97774 1.24693 6.62158 1.93116 6.62158H22.9396C23.8393 6.62158 24.6756 7.10493 25.119 7.89356L31.6066 19.2014C31.9487 19.7993 31.9487 20.5116 31.6066 21.1094C31.2645 21.6945 30.6436 22.0507 29.9594 22.0507ZM2.24793 8.70763L8.59609 19.7484C8.67212 19.8883 8.8115 19.9646 8.97622 19.9646H29.6426L23.3198 8.92386C23.2437 8.78395 23.1044 8.70763 22.9396 8.70763H2.24793Z" fill="#035537"/>
<path d="M29.932 28.7543H8.94888C8.04924 28.7543 7.21296 28.2709 6.76947 27.495L0.256587 16.1871C-0.0855291 15.5893 -0.0855291 14.877 0.256587 14.2792C0.598704 13.6813 1.21958 13.3252 1.90381 13.3252H22.9123C23.8119 13.3252 24.6482 13.8085 25.0917 14.5972L31.5792 25.9051C31.9214 26.5029 31.9214 27.2152 31.5792 27.813C31.2371 28.3981 30.6162 28.7543 29.932 28.7543ZM2.22059 15.4112L8.56875 26.452C8.64477 26.5919 8.78415 26.6682 8.94888 26.6682H29.6152L23.2798 15.6402C23.2037 15.5003 23.0643 15.424 22.8996 15.424H2.22059V15.4112Z" fill="#035537"/>
<path d="M36.3086 11.8907V10.5301C36.7674 10.5301 37.1313 10.5143 37.4002 10.4668C37.6692 10.4194 37.8748 10.3086 38.0172 10.1188C38.1438 9.94476 38.2387 9.64417 38.2703 9.21701C38.302 8.80568 38.3336 8.23614 38.3336 7.54004H39.8998V10.5143H42.5735V11.8749H36.3086V11.8907ZM40.0106 22.0791C39.3303 22.0791 38.8399 21.8892 38.5551 21.5095C38.2703 21.1298 38.1279 20.6077 38.1279 19.9433V11.6217H39.884V20.5919H42.5893V22.0632H40.0106V22.0791Z" fill="#035537"/>
<path d="M47.3051 22.3324C46.2451 22.3324 45.375 22.0793 44.6947 21.5572C44.0145 21.0352 43.5082 20.3391 43.176 19.4373C42.8438 18.5513 42.6855 17.5388 42.6855 16.3998C42.6855 15.229 42.8596 14.2007 43.1918 13.299C43.5398 12.413 44.0461 11.7011 44.7422 11.179C45.4383 10.6569 46.2926 10.4038 47.3051 10.4038C47.9063 10.4038 48.4284 10.4829 48.8872 10.6411C49.3301 10.7993 49.7098 11.0208 50.0421 11.2739C50.3585 11.5429 50.6274 11.8118 50.8489 12.1124C51.0704 12.413 51.2286 12.682 51.3393 12.9509H51.371V10.5146H53.2378V22.0635H51.6716L51.3868 19.817H51.3235C51.197 20.1018 51.0229 20.3865 50.8173 20.6871C50.6116 20.9877 50.3427 21.2566 50.0104 21.494C49.6782 21.7471 49.2985 21.9369 48.8397 22.0951C48.4126 22.2533 47.8905 22.3324 47.3051 22.3324ZM48.017 20.7188C48.8081 20.7188 49.4567 20.5447 49.9313 20.1967C50.4218 19.8486 50.7698 19.3424 50.9913 18.7096C51.2128 18.0609 51.3393 17.3015 51.3393 16.4314C51.3393 15.498 51.2286 14.7228 50.9913 14.0742C50.754 13.4255 50.3901 12.9509 49.8997 12.6187C49.4092 12.2864 48.7764 12.1282 48.0329 12.1282C47.1944 12.1282 46.5299 12.3181 46.0078 12.6978C45.4858 13.0775 45.1061 13.5837 44.8846 14.2324C44.6631 14.881 44.5365 15.6087 44.5365 16.4156C44.5365 17.2541 44.6473 17.9976 44.8846 18.6463C45.1219 19.2949 45.5016 19.817 46.0237 20.1809C46.5299 20.5289 47.1944 20.7188 48.017 20.7188Z" fill="#035537"/>
<path d="M52.873 11.8907V10.5301C53.3318 10.5301 53.6957 10.5143 53.9647 10.4668C54.2336 10.4194 54.4393 10.3086 54.5817 10.1188C54.7082 9.94476 54.8031 9.64417 54.8348 9.21701C54.8664 8.80568 54.8981 8.23614 54.8981 7.54004H56.4643V10.5143H59.1379V11.8749H52.873V11.8907ZM56.5592 22.0791C55.8789 22.0791 55.3885 21.8892 55.1037 21.5095C54.819 21.1298 54.6766 20.6077 54.6766 19.9433V11.6217H56.4327V20.5919H59.1379V22.0632H56.5592V22.0791Z" fill="#035537"/>
<path d="M63.852 22.3324C62.792 22.3324 61.9219 22.0793 61.2416 21.5572C60.5613 21.0352 60.0551 20.3391 59.7229 19.4373C59.3906 18.5513 59.2324 17.5388 59.2324 16.3998C59.2324 15.229 59.4064 14.2007 59.7387 13.299C60.0867 12.413 60.593 11.7011 61.2891 11.179C61.9852 10.6569 62.8395 10.4038 63.852 10.4038C64.4532 10.4038 64.9753 10.4829 65.434 10.6411C65.877 10.7993 66.2567 11.0208 66.5889 11.2739C66.9053 11.5429 67.1743 11.8118 67.3958 12.1124C67.6173 12.413 67.7755 12.682 67.8862 12.9509H67.9179V10.5146H69.7847V22.0635H68.2184L67.9337 19.817H67.8704C67.7438 20.1018 67.5698 20.3865 67.3641 20.6871C67.1585 20.9877 66.8895 21.2566 66.5573 21.494C66.2251 21.7471 65.8454 21.9369 65.3866 22.0951C64.9594 22.2533 64.4374 22.3324 63.852 22.3324ZM64.5639 20.7188C65.3549 20.7188 66.0036 20.5447 66.4782 20.1967C66.9686 19.8486 67.3167 19.3424 67.5382 18.7096C67.7597 18.0609 67.8862 17.3015 67.8862 16.4314C67.8862 15.498 67.7755 14.7228 67.5382 14.0742C67.3009 13.4255 66.937 12.9509 66.4466 12.6187C65.9561 12.2864 65.3233 12.1282 64.5797 12.1282C63.7413 12.1282 63.0768 12.3181 62.5547 12.6978C62.0326 13.0775 61.6529 13.5837 61.4315 14.2324C61.21 14.881 61.0834 15.6087 61.0834 16.4156C61.0834 17.2541 61.1942 17.9976 61.4315 18.6463C61.6688 19.2949 62.0485 19.817 62.5705 20.1809C63.0768 20.5289 63.7571 20.7188 64.5639 20.7188Z" fill="#035537"/>
<path d="M70.2129 22.0792V10.5303H71.9215L72.0006 13.3938H72.0639C72.0955 13.2672 72.1905 13.0457 72.3328 12.7293C72.4752 12.4129 72.6809 12.0965 72.9498 11.7485C73.2188 11.4004 73.5827 11.1156 74.0414 10.8783C74.5002 10.641 75.0698 10.5303 75.7659 10.5303C76.4778 10.5303 77.079 10.6727 77.5219 10.9416C77.9807 11.2105 78.3288 11.5744 78.5661 12.0016C78.8192 12.4445 79.0091 12.9033 79.1356 13.3938H79.1989C79.2306 13.2356 79.3255 13.0141 79.4837 12.7135C79.6419 12.4129 79.8634 12.0965 80.164 11.7485C80.4646 11.4004 80.8601 11.1156 81.3347 10.8783C81.8251 10.641 82.4105 10.5303 83.1066 10.5303C83.8501 10.5303 84.4513 10.6727 84.9418 10.9732C85.4322 11.2738 85.8119 11.6535 86.0966 12.144C86.3814 12.6344 86.5871 13.1406 86.7136 13.6627C86.8402 14.2006 86.9193 14.7069 86.951 15.1973C86.9826 15.6877 87.0142 16.0833 87.0142 16.3838V22.0792H85.2265V16.368C85.2265 16.0516 85.2107 15.6561 85.1791 15.1973C85.1474 14.7385 85.0525 14.2639 84.8943 13.8051C84.7361 13.3463 84.4671 12.9508 84.1191 12.6344C83.7552 12.318 83.249 12.1598 82.5845 12.1598C81.8726 12.1598 81.2714 12.3654 80.8126 12.7768C80.3538 13.1881 80.0058 13.7418 79.8001 14.4063C79.5944 15.0707 79.4837 15.7985 79.4837 16.5737V22.0792H77.7276V16.368C77.7276 16.0516 77.7118 15.6561 77.6643 15.1973C77.6327 14.7385 77.5378 14.2639 77.3796 13.8051C77.2214 13.3463 76.9682 12.9508 76.6202 12.6344C76.2563 12.318 75.7501 12.1598 75.0856 12.1598C74.342 12.1598 73.7567 12.3654 73.2979 12.7768C72.8391 13.1881 72.5069 13.7418 72.3012 14.4063C72.0955 15.0707 71.9848 15.7985 71.9848 16.5737V22.0792H70.2129Z" fill="#035537"/>
<path d="M88.8959 8.79006C88.4213 8.79006 88.1049 8.6635 87.9309 8.42619C87.7568 8.17307 87.6777 7.90411 87.6777 7.57188C87.6777 7.2713 87.7568 7.00235 87.9309 6.76504C88.1049 6.52774 88.4213 6.41699 88.8959 6.41699C89.3705 6.41699 89.6869 6.52774 89.8293 6.76504C89.9875 7.00235 90.0508 7.2713 90.0508 7.57188C90.0508 7.88829 89.9717 8.17307 89.8293 8.42619C89.6869 8.6635 89.3705 8.79006 88.8959 8.79006ZM87.9625 22.0793V10.5303H89.8135V22.0793H87.9625Z" fill="#035537"/>
</svg>`;
