import { useState } from "react";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import Drawer from "@mui/material/Drawer";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { navLinks } from "./nav-links";
import { joinWaitlistLink } from "../../data/links";

export default function MobileDrawer() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <div>
      <button
        className="text-white p-2 -mr-2"
        onClick={() => setDrawerOpen(true)}
      >
        <FiMenu size={32} color="inherit" />
      </button>

      <Drawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        anchor="top"
        classes={{ root: "h-full" }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100vw",
            height: "100dvh",
            boxSizing: "border-box",
          },
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              p: 3,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <a href="/">
              <img src="/assets/tatami-logo-primary.svg" alt="tatami logo" />
            </a>

            <Button onClick={() => setDrawerOpen(false)}>
              <AiOutlineClose size={26} color="005458" />
            </Button>
          </Box>

          <List
            onClick={() => setDrawerOpen(false)}
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            {navLinks.map(({ label, href }) => (
              <ListItem key={label} disablePadding>
                <ListItemButton href={href}>
                  <ListItemText
                    primary={label}
                    sx={{
                      textAlign: "center",
                      color: "#005458",
                      "& .MuiListItemText-primary": {
                        fontFamily: "Instrument-sans",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}

            <Box sx={{ flex: 1 }} />

            <ListItem sx={{ justifySelf: "flex-end", my: 4 }}>
              <Button
                href={joinWaitlistLink}
                target="_blank"
                variant="contained"
                disableElevation
                sx={{
                  backgroundColor: "#005458",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#005458",
                  },
                  margin: "auto",
                  textTransform: "unset",
                  fontFamily: "Instrument-sans",
                }}
              >
                Join the waitlist
              </Button>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
}
