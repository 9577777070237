import { FC, useEffect, useRef } from "react";
import { FaLinkedin } from "react-icons/fa";
import Flickity from "flickity";
// import 'flickity/dist/flickity.min.css';
import "flickity/css/flickity.css";

const members = [
  {
    name: "AIMEN BEDAWI",
    linkedinUrl: "https://www.linkedin.com/in/aimenbedawi/",
    role: "CoFounder, CEO",
    image: "/assets/aimen.png",
  },
  {
    name: "OMAR AL KHAL",
    linkedinUrl: "https://www.linkedin.com/in/omar-al-khal-050220b7/",
    role: "CoFounder, CGO",
    image: "/assets/omar.png",
  },
  {
    name: "GEOFFREY DOBSON",
    linkedinUrl: "https://www.linkedin.com/in/geoffrey-dobson-347970/",
    role: "Advisor",
    image: "/assets/dobson.png",
  },
];

const Team: FC = () => {
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const flkty = useRef<Flickity | null>(null);

  useEffect(() => {
    if (carouselRef.current) {
      // Initialize Flickity only if the carouselRef is not null
      flkty.current = new Flickity(carouselRef.current, {
        cellAlign: "center",
        contain: true,
        pageDots: false,
        // autoPlay: 3000,  // 3 seconds autoplay
        prevNextButtons: true,
        wrapAround: false,
      });
    }

    return () => {
      // Destroy Flickity instance only if it's initialized
      // if (flkty.current) {
      //   flkty.current.destroy();
      // }
    };
  }, []);

  return (
    <div className="team relative w-full bg-[#F9FBFB]">
      <div className="team-extra w-full app-spacing-x pt-1 pb-[69px] ">
        <h2 className="mt-20 font-darker font-semibold text-3xl md:text-5xl text-center">
          Meet the team behind Tatami
        </h2>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-5 mt-8">
          {members.map((member) => (
            <div
              key={member.name}
              className="members flex flex-col justify-center"
            >
              <img src={member.image} alt={member.name} />

              <div className="flex items-center justify-between bg-[#F5F0F4] mt-2 px-4 py-2.5 rounded-[6px]">
                <div className="text-start text-[#4A4A4A]">
                  <p className="font-instrument font-normal text-xs">
                    {member.role}
                  </p>
                  <p className="font-fig font-semibold text-base -mt-0.5">
                    {member.name}
                  </p>
                </div>

                <a
                  href={member.linkedinUrl}
                  rel="noreferrer"
                  target="_blank"
                  className="hover:scale-110 transition"
                >
                  <FaLinkedin color="#4A4A4A" size={22} />
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="ta-slides bg-[#FAF5FA] pt-24 pb-10 md:pb-24">
        <h5 className="text-center font-instrument font-normal max-w-[530px] m-auto px-6">
          Check out our pitch deck to get a detailed overview of our business
          model, market opportunity and growth strategy. We are fundraising!
        </h5>

        <div className="container-xl">
          <div className="flickity-container">
            <div className="carousel" ref={carouselRef} data-flickity>
              <div className="carousel-cell yooy">
                <div className="random-cell"></div>
                <img src="/assets/slide1.svg" alt="Slide 1" />
              </div>
              <div className="carousel-cell">
                <img src="/assets/slide2.svg" alt="Slide 2" />
              </div>
              <div className="carousel-cell">
                <img src="/assets/slide3.svg" alt="Slide 3" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide4.svg" alt="Slide 4" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide5.svg" alt="Slide 5" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide6.svg" alt="Slide 6" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide7.svg" alt="Slide 7" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide8.svg" alt="Slide 8" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide9.svg" alt="Slide 9" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide10.svg" alt="Slide 10" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide11.svg" alt="Slide 11" />
              </div>

              <div className="carousel-cell">
                <img src="/assets/slide12.svg" alt="Slide 12" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
