import { FC, useState } from "react";
import clsx from "clsx";

const userInstructions = {
  landlord: [
    {
      icon: "/assets/pPerson.svg",
      title: "Sign up",
      description:
        "Create your account to customize your experience according to your needs.",
    },
    {
      icon: "/assets/Wallet-p.svg",
      title: "Payment Set Up",
      description:
        "Select your preferred payment method and link your bank account.",
    },
    {
      icon: "/assets/collectRent.svg",
      title: "Collect Rent",
      description: "Receive rent, track and manage incoming payments.",
    },
  ],
  tenant: [
    {
      icon: "/assets/yPerson.svg",
      title: "Sign up",
      description:
        "Create your account. This step will help us tailor your experience to your needs.",
    },
    {
      icon: "/assets/Wallet-y.svg",
      title: "Set up payment method",
      description:
        "Choose your preferred payment method and link your bank account or credit card.",
    },
    {
      icon: "/assets/payRent.svg",
      title: "Pay rent",
      description: "Tenants can easily pay rent through the platform.",
    },
  ],
};

const About: FC = () => {
  const [isTenant, setIsTenant] = useState(true);
  const [isLandLord, setIsLandLord] = useState(false);

  const handleToogleTenant = () => {
    if (isLandLord) {
      setIsLandLord(false);
    }
    setIsTenant(true);
  };

  const handleToogleLandlord = () => {
    if (isTenant) {
      setIsTenant(false);
    }
    setIsLandLord(true);
  };

  return (
    <div className="about relative">
      {/* -----> SECTION ONE BEGINS <---- */}

      <div className="py-12 lg:py-20 app-spacing-x">
        <h4 className="font-darker text-gray-300 mb-4 md:mb-6 lg:mb-12 text-center section-title">
          Convenience and security all in one platform
        </h4>

        <div className="flex flex-col gap-4 md:flex-row md:gap-6 lg:gap-7 max-w-[900px] mx-auto">
          <div className="rounded-2xl border border-[#FFD1E3] bg-[#FFF5F9] p-4 text-center md:p-5">
            <img
              alt=""
              src="/assets/feat-convenient.svg"
              className="m-auto mb-2"
            />

            <p className="font-darker text-xl mb-2 font-semibold lg:text-2xl">
              Convenient
            </p>
            <p className="font-instrument text-sm lg:text-base font-light text-[#595959]">
              Simplify your rent payments with Tatami. Enjoy the convenience of
              managing your rent payments with ease, save time and eliminate
              stress.
            </p>
          </div>

          <div className="rounded-2xl border border-[#F6D1FF] bg-[#FDF5FF] p-4 text-center md:p-5">
            <img alt="" src="/assets/feat-secure.svg" className="m-auto mb-2" />

            <p className="font-darker text-xl mb-2 font-semibold lg:text-2xl">
              Secure
            </p>
            <p className="font-instrument text-sm lg:text-base font-light text-[#595959]">
              Enjoy peace of mind with our secure payment options, including
              standing orders, pre-authorised direct debits and seamless
              withdrawals.
            </p>
          </div>
        </div>
      </div>
      {/* -----> SECTION TWO BEGINS <---- */}

      <div className="about-cont-2 app-spacing-x bg-[#FFFAF5] items-center w-full py-12 lg:py-20">
        <div className="container-xl">
          <h4 className="lg:hidden block text-center section-title mb-8">
            The new way to pay and collect rent is here
          </h4>

          <div className="lg:flex lg:gap-16">
            <div className="lg:w-[55%]">
              <img src="/assets/about-cont-2-bg.svg" alt="Webapp view" />
            </div>
            <div className="lg:w-[45%] flex flex-col items-start justify-center px-4">
              <h4 className="hidden lg:block max-w-[450px] pb-3.5 text-start font-darker text-gray-300 section-title">
                The new way to pay and collect rent is here
              </h4>

              <div className="cont-2-text tracking-wide">
                <div className="flex items-center border-b border-solid border-b-[#E8E8E8] pt-6 pb-5">
                  <p className="font-darker font-semibold text-gray-300 text-[20px]">
                    Digital Payment Platform
                  </p>
                </div>

                <div className="flex items-center border-b border-solid border-b-[#E8E8E8] py-5">
                  <p className="font-darker font-semibold text-gray-300 text-[20px]">
                    Pre-authorized Direct Debits and Standing Orders
                  </p>
                </div>

                <div className="flex items-center border-b border-solid border-b-[#E8E8E8] py-5 gap-x-1.5">
                  <p className="font-darker font-semibold text-gray-300 text-[20px]">
                    Rewards Program
                  </p>
                  <p className="font-fig font-medium text-white text-[10px] px-[6px] py-[3.3px] bg-[#005458] rounded-[5px]">
                    COMING SOON
                  </p>
                </div>

                <div className="flex items-center border-b border-solid border-b-[#E8E8E8] py-5 gap-x-1.5">
                  <p className="font-darker font-semibold text-gray-300 text-[20px]">
                    Credit Reporting
                  </p>
                  <p className="font-fig font-medium text-white text-[10px] px-[6px] py-[3.3px] bg-[#005458] rounded-[5px]">
                    COMING SOON
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -----> SECTION THREE BEGINS <---- */}

      <div className="about-cont-3 pt-[80px] pb-[100px] w-full">
        <h4 className="text-center section-title">Using Tatami is Easy</h4>
        <div className="cont-3-toggle mt-6 w-[260px] flex items-center justify-center relative m-auto left-0 right-0">
          <button
            onClick={() => handleToogleTenant()}
            className={`px-4 py-[14px] w-[45%] rounded-l-[5px] border-l border-y border-solid border-[#A1A1A1] cursor-pointer ${
              isTenant
                ? "bg-yellow-300 text-white hover:opacity-95 "
                : " bg-white text-gray-300 hover:bg-[#FFFAF5]"
            }`}
          >
            <p className="font-fig font-medium text-[18px] text-center">
              Tenant
            </p>
          </button>

          <button
            onClick={() => handleToogleLandlord()}
            className={`px-4 py-[14px] w-[55%] rounded-r-[5px] border-r border-y border-solid border-[#A1A1A1] cursor-pointer ${
              isLandLord
                ? "bg-purple-300 text-white hover:opacity-95"
                : "bg-white text-gray-300 hover:bg-[#FFFAF5]"
            }`}
          >
            <p className="font-fig font-medium text-[18px] text-center">
              Landlord
            </p>
          </button>
        </div>

        <div className="toggle-content flex flex-col lg:flex-row items-center justify-center mt-12">
          {(isLandLord
            ? userInstructions.landlord
            : userInstructions.tenant
          ).map((item, index) => (
            <div className="flex flex-col lg:flex-row items-center">
              {index > 0 && (
                <>
                  <div className="hidden lg:block h-[7rem] w-[1.5px] bg-[#E8E8E8]"></div>

                  <div className="block lg:hidden h-[1.5px] w-[60%] bg-[#E8E8E8]"></div>
                </>
              )}

              <div
                key={item.title}
                className={clsx(
                  "content-card-1 flex flex-col items-center py-8 px-10 text-gray-300"
                )}
              >
                <img src={item.icon} alt="Person Icon" />
                <h6 className="mt-4 font-darker font-semibold text-3xl text-center">
                  {item.title}
                </h6>
                <p className="mt-1 font-instrument font-normal text-center max-w-[400px] lg:max-w-[320px]">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* -----> SECTION FOUR BEGINS <---- */}

      <div
        id="waitlist"
        className="about-cont-4 w-full h-[500px] py-8 px-12 flex flex-col items-center justify-center text-white"
      >
        <h5 className="font-darker font-semibold text-3xl md:text-6xl text-center max-w-[550px] mt-4 mb-4">
          Ready for the future of rent payments?
        </h5>
        <p className="font-instrument font-normal lg:text-lg text-center max-w-[500px] mt-2.5">
          Join the growing number of tenants and landlords in Qatar who are
          making the switch to digital rent payments.
        </p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeJnQBX4lqqGEsCmq-IAIF6rr468dEG3FcaBuZqV25RPemjqQ/viewform"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button
            type="button"
            className="mt-10 bg-white hover:opacity-90 px-[32px] py-[14.5px] rounded-[4px] font-instrument font-medium text-[15px] text-gray-300 shadow-s-light"
          >
            Join the waitlist
          </button>
        </a>
      </div>
    </div>
  );
};

export default About;
