import { FC, useState, useEffect } from "react";
import MobileDrawer from "./mobile-header";
import { navLinks } from "./nav-links";
import { joinWaitlistLink } from "../../data/links";

const Navbar: FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`navbar fixed w-full z-20 ${
        isScrolled ? "bg-black/40" : "bg-transparent"
      }`}
    >
      <div className="flex items-center justify-between container-xl app-spacing-x py-5">
        <div className="nav-logo w-[116px]">
          <a href="/">
            <img className="" src="/tatami-logo.svg" alt="tatami logo" />
          </a>
        </div>

        <div
          className={`hidden nav-menu lg:flex items-center justify-between font-instrument font-normal text-[14px] py-1.5 px-7 w-[430px] ${
            isScrolled
              ? "text-white bg-[#00000022]"
              : "text-black bg-[#FFFFFF33]"
          } rounded-[4px]`}
        >
          {navLinks.map(({ label, href }) => (
            <a
              key={label}
              href={href}
              className={`${
                isScrolled ? "hover:bg-[#00000022]" : "hover:bg-[#FFFFFF33]"
              } px-1.5 py-[1px] hover:rounded-[4px] hover:font-medium shadow-s-light transition-all duration-300`}
            >
              {label}
            </a>
          ))}
        </div>

        <div className="nav-btn hidden lg:block">
          <a href={joinWaitlistLink} target="_blank" rel="noreferrer">
            <button
              type="button"
              className="bg-white hover:opacity-80 transition px-[32px] py-[9px] rounded-[4px] font-instrument font-medium text-[15px] text-gray-300 shadow-s-light"
            >
              Join the waitlist
            </button>
          </a>
        </div>

        <div className="lg:hidden">
          <MobileDrawer />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
