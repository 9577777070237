import { FC } from "react";
import { joinWaitlistLink } from "../../data/links";

const Home: FC = () => {
  return (
    <div className="home pt-8 relative z-10">
      <div className="hero-bg absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-b from-[#00000016] to-[#000000a5]"></div>

      <div className="home-cont text-white text-center absolute bottom-0 left-0 right-0 pb-14 pt-1 app-spacing-x">
        <h4 className="font-darker text-5xl md:text-7xl lg:text-[90px] xl:text-8xl font-semibold mb-20 lg:mb-6 max-w-[400px] md:max-w-[600px] mx-auto lg:max-w-none">
          <span className="text-left lg:inline block">Rent payments, </span>
          <span className="text-right lg:inline block">made easier</span>
        </h4>
        <p className="hidden lg:block font-instrument text-[16px] font-normal md:text-lg xl:text-xl -mt-[14px]">
          Tatami provides a seamless rent payment/collection platform for
          renters and landlords
        </p>
        <a href={joinWaitlistLink} rel="noreferrer">
          <button
            type="button"
            className="bg-gray-400 hover:opacity-90 px-[32px] py-[10px] rounded-[4px] font-instrument font-medium text-[15px] text-white shadow-s-light mt-6"
          >
            Join the waitlist
          </button>
        </a>
      </div>
    </div>
  );
};

export default Home;
