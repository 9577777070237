import { FC } from "react";
import { footerLogo } from "../../common/svg";

const Footer: FC = () => {
  return (
    <footer>
      <div className="footer w-full h-auto bg-[#F6FEF9] py-8">
        <div className="w-full flex flex-col lg:flex-row gap-8 justify-between items-center app-spacing-x container-xl">
          <a href="#home">
            <span dangerouslySetInnerHTML={{ __html: footerLogo }} />
          </a>

          <div className="footer-links flex flex-col lg:flex-row items-center gap-y-4 gap-x-6">
            <p className="cursor-pointer font-instrument font-normal text-[13.5px] text-[#1B2919] hover:scale-95 transition hover:text-[#035537]">
              Privacy Policy
            </p>
            <p className="cursor-pointer font-instrument font-normal text-[13.5px] text-[#1B2919] hover:scale-95 transition hover:text-[#035537]">
              Terms of Service
            </p>
            <p className="cursor-pointer font-instrument font-normal text-[13.5px] text-[#1B2919] hover:scale-95 transition hover:text-[#035537]">
              Data Protection
            </p>
          </div>

          <p className="font-instrument font-normal text-[13.5px] text-[#1B2919] text-center">
            (c) Tatami 2024
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
