export const navLinks = [
  {
    label: "Home",
    href: "#home",
  },
  {
    label: "About us",
    href: "#about-us",
  },
  {
    label: "The team",
    href: "#our-team",
  },
  {
    label: "Contact us",
    href: "#contact-us",
  },
];
