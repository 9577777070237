import { FC } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { MdPhone, MdEmail } from "react-icons/md";

import { shapeIcon } from "../../common/svg";

const Contact: FC = () => {
  return (
    <div className="contact w-full h-auto bg-[#FAF5FA] py-20">
      <div className="contact-us w-full flex flex-col-reverse lg:flex-row items-center justify-center container-xl app-spacing-x">
        <div className="contact-notes text-black w-full lg:w-[39%] -mt-2.5">
          <div className="flex flex-col items-start justify-center">
            <span dangerouslySetInnerHTML={{ __html: shapeIcon }} />
            <h3 className="mt-4 font-darker font-semibold text-3xl md:text-4xl lg:text-5xl leading-7">
              Get in touch with us
            </h3>
            <p className="mt-3 font-instrument font-normal">
              Have questions or need support? We’re here to help.
            </p>
          </div>

          <div className="socials text-black mt-6 space-y-4">
            <div className="socials-content flex items-center">
              <div className="bg-[#E2F4EE] h-[30px] w-[30px] flex justify-center items-center rounded-md text-[#035537]">
                <FaLinkedinIn />
              </div>
              <p className="ml-2.5 font-instrument font-normal">
                Contact us on{" "}
                <a
                  href="https://www.linkedin.com/company/tatamihq/"
                  target="_blank"
                  className="cursor-pointer underline underline-offset-2"
                >
                  LinkedIn
                </a>
              </p>
            </div>

            <div className="socials-content flex items-center">
              <div className="bg-[#E2F4EE] h-[30px] w-[30px] flex justify-center items-center rounded-md text-[#035537]">
                <RiInstagramFill />
              </div>
              <p className="ml-2.5 font-instrument font-normal">
                <a
                  target="_blank"
                  href="https://www.instagram.com/tatamihq/"
                  className="cursor-pointer underline underline-offset-2"
                >
                  @tatamihq
                </a>
              </p>
            </div>

            <div className="socials-content flex items-center">
              <div className="bg-[#E2F4EE] h-[30px] w-[30px] flex justify-center items-center rounded-md text-[#035537]">
                <MdPhone />
              </div>
              <a
                href="tel:+97460044477"
                className="ml-2.5 font-instrument text-[14px] font-normal"
              >
                +974 6004 4477
              </a>
            </div>

            <div className="socials-content flex items-center">
              <div className="bg-[#E2F4EE] h-[30px] w-[30px] flex justify-center items-center rounded-md text-[#035537]">
                <MdEmail />
              </div>
              <a
                href="mailto:hello@tatamihq.com"
                className="ml-2.5 font-instrument text-[14px] font-normal"
              >
                hello@tatamihq.com
              </a>
            </div>
          </div>
        </div>

        <div className="contact-image lg:w-[51%] w-full">
          <img
            className="w-full"
            src="/assets/footerImg.svg"
            alt="Contact us pics"
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
