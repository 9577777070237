import About from "../components/about";
import Contact from "../components/contact";
import Footer from "../components/footer";
import Home from "../components/home";
import Navbar from "../components/navbar";
import Team from "../components/team";

function App() {
  return (
    <div className="App">
      <section className="navbar ">
        <Navbar />
      </section>

      <section className="home" id="home">
        <Home />
      </section>

      <section className="about" id="about-us">
        <About />
      </section>

      <section className="team" id="our-team">
        <Team />
      </section>

      <section className="contact" id="contact-us">
        <Contact />
      </section>

      <section className="footer" id="footer">
        <Footer />
      </section>
    </div>
  );
}

export default App;
